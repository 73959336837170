.questions .containers {
  padding: 0px 0px 70px 0px;
  text-align: justify;
}

.questions .ant-collapse-borderless {
  background-color: #ffffff !important;
}
.questions .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.questions .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 9px 0px 9px 20px !important;
  color: black !important;
  letter-spacing: 0px;
  word-spacing: 0px;
  line-height: 1.5;
  background-color: #e5e5e5;
}

.questions .title-questions {
  font-weight: 500;
  font-size: 25px !important;
  text-align: center;
  color: #e75f3a;
  text-align: center;
  letter-spacing: 0px;
  word-spacing: 0px;
  margin-bottom: 3px;
}
.questions .containers p {
  font-size: 14px;
  padding: 0px 20px;
}

.questions
  .ant-collapse
  > .ant-collapse-item
  .ant-collapse-header-collapsible-only
  .ant-collapse-header-text {
  padding-right: 10px;
}
.questions .ant-btn:active,
.questions .ant-btn:focus,
.questions .ant-btn:hover {
  color: unset;
  border-color: #e75f3a;
}

.questions .panel-title {
  font-size: 14px;
  padding-right: 40px !important;
}
