@font-face {
  font-family: Averta;
  font-weight: 400;
  src: url(assets/fonts/Averta-Regular.otf);
}
@font-face {
  font-family: Averta;
  font-weight: 500;
  src: url(assets/fonts/Averta-Bold.otf);
}
@font-face {
  font-family: Averta;
  font-weight: 700;
  src: url(assets/fonts/Averta-Bold.otf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-family: Averta;
  word-break: break-word;
  color: #3f4142;
}

.anticon {
  vertical-align: 3px !important;
}

.ant-collapse-item-active .ant-collapse-header .panel-title {
  color: #e75f3a;
}
