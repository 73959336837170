.term .containers {
  padding: 0px 0px 70px 0px;
  text-align: justify;
}

.term .ant-collapse-borderless {
  background-color: #ffffff !important;
}
.term .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.term .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 9px 0px 9px 20px !important;
  color: black !important;
  letter-spacing: 0px;
  word-spacing: 0px;
  line-height: 1.5;
  background-color: #e5e5e5;
}

.term .title-term {
  font-weight: 500;
  font-size: 25px !important;
  text-align: center;
  color: #e75f3a;
  text-align: center;
  letter-spacing: 0px;
  word-spacing: 0px;
  margin-bottom: 3px;
}
.term .containers p {
  font-size: 14px;
  padding: 0px 20px;
}

.term .containers ul li p {
  padding-right: 0px;
}

.term .containers li {
  font-size: 14px;
}

.term
  .ant-collapse
  > .ant-collapse-item
  .ant-collapse-header-collapsible-only
  .ant-collapse-header-text {
  padding-right: 10px;
}
.term .ant-btn:active,
.term .ant-btn:focus,
.term .ant-btn:hover {
  color: unset;
  border-color: #e75f3a;
}

.term .panel-title {
  font-size: 14px;
  padding-right: 40px !important;
}

.term .containers .term-list {
  padding-left: 35px;
  padding-right: 16px;
}
